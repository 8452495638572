import React from 'react';
import { navigate } from 'gatsby';
import Layout from '../components/Layout';
import Title from '../components/Title/Title';
import Button from '../components/Button/Button';
import GlobalWrapper from '../components/GlobalWrapper/GlobalWrapper';
import * as styles from '../styles/pages/shortHeightContent.module.css';

import { GtagHead } from '../components/GTAG';
export function Head() {
  return (
    <>
      <GtagHead id='gtag' />
      <title>LOQTORZI® - 404 page</title>
      <meta name='description'
        content='Not found 404 page'/>
    </>
  )
}

export default function Page404() {
  const handleClick = () => {
    navigate('/');
  };

  return (
    <Layout className={ styles.shortHeightContent } showIsi={false}>
      <Title pageName={'404 PAGE NOT FOUND'} center={true}>
        404 <br />
        NOT FOUND
      </Title>
      <div className={ styles.content }>
        <GlobalWrapper className={'flex-1 w-full'} showBorderLeft={true}>
          <div className={'mr-[2.6rem] tablet:mr-0'}>
            <div className='font-MontserratBold text[1.6rem] text-center pt-[3rem]'>
              We’re sorry.<br />
              The page you are looking for does <br className='desktop:hidden'/>
              not exist, <br className='hidden desktop:block'/>may have been moved, <br className='desktop:hidden'/>
              or was removed.
            </div>
            <div className='flex justify-center pt-[2rem] pb-[3.7rem]'>
              <Button onClick={handleClick}>
                <span>back to home</span>
              </Button>
            </div>
            <div className='font-MontserratRegular text-[.8rem] text-center pb-[2rem] tablet:text-[1.4rem]'>R/M NPC=recurrent locally advanced/metastatic nasopharyngeal carcinoma.</div>
          </div>
        </GlobalWrapper>
      </div>
    </Layout>
  );
}